import React from 'react'
import { Container, Menu } from 'semantic-ui-react'
import { permissionEnum, DropdownLinks, MenuLinkItem } from '../shared'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const NavigationContainer = styled(Container)`
  margin-top: 2em !important;
  margin-bottom: 1em !important;
`

export const Navigation = () => {
  const { pathname } = useLocation()
  return (
    <NavigationContainer>
      <Menu color="blue" inverted>
        <DropdownLinks
          permission={permissionEnum.MARKET}
          item
          floating
          text="Market"
          dropdownItems={[
            {
              content: 'EPS',
              to: '/market/eps',
              permission: permissionEnum.MARKET_EPS,
            },
            {
              content: 'Financial Reporting Risk',
              to: '/market/financial-reporting-risk/company-ranking-quarterly',
              active: pathname.startsWith('/market/financial-reporting-risk'),
              permission: permissionEnum.MARKET_FINANCIAL_REPORTING_RISK,
            },
          ]}
        />
        <MenuLinkItem
          permission={permissionEnum.COMPANY_SEARCH}
          to="/company/most-searched-companies"
          content="Company"
          active={pathname.startsWith('/company')}
        />
        <MenuLinkItem
          permission={permissionEnum.CALENDAR}
          to="/calendar"
          content="Calendar"
        />
        <MenuLinkItem
          permission={permissionEnum.PORTFOLIO}
          to="/portfolio"
          content="Portfolio"
        />
      </Menu>
    </NavigationContainer>
  )
}
