import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  Form,
  Grid,
  Segment,
  Message,
  Image,
  Icon,
  Select,
} from 'semantic-ui-react'

import { QuickLookUp } from './QuickLookUp'

const clientOptions = [
  { key: 'financial', value: 'financial', text: 'Asset Management Clients' },
  { key: 'litigation', value: 'litigation', text: 'Legal/PR Clients' },
  { key: 'trial', value: 'trial', text: 'Trial Clients' },
  { key: 'developer', value: 'developer', text: 'Developer' },
]

const LogoContainer = styled.div`
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogIn = () => {
  const [formState, setFormState] = useState({
    clientType: 'financial',
    email: '',
    password: '',
  })

  const handleSubmit = () => {
    const { clientType, email, password } = formState
    console.log('clientType', clientType)
    console.log('email', email)
    console.log('password', password)
  }

  const handleChange = (e, { name, value }) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  return (
    <Grid
      textAlign="center"
      style={{ height: '100vh' }}
      divided
      verticalAlign="middle"
      columns={2}
    >
      <Grid.Column verticalAlign="middle">
        <div style={{ maxWidth: '500px', margin: 'auto' }}>
          <LogoContainer>
            <Image src="/img/logo.png" size="medium" />
          </LogoContainer>
          <QuickLookUp />
        </div>
      </Grid.Column>
      <Grid.Column verticalAlign="middle">
        <div style={{ maxWidth: '500px', margin: 'auto' }}>
          <LogoContainer>
            <Icon name="users" size="massive" />
          </LogoContainer>
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <p
                style={{
                  fontSize: '18px',
                  marginBottom: '2rem',
                  fontWeight: 'bold',
                }}
              >
                Clients, please log in.
              </p>
              <Form.Field
                name="clientType"
                control={Select}
                options={clientOptions}
                required
                placeholder="Client type"
                value={formState.clientType}
                onChange={handleChange}
              />
              <Form.Input
                name="email"
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                required
                value={formState.email}
                onChange={handleChange}
              />
              <Form.Input
                fluid
                name="password"
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                required
                value={formState.password}
                onChange={handleChange}
              />
              <Button
                class
                name="Submit"
                fluid
                size="large"
                style={{
                  backgroundColor: '#033be7',
                  color: '#FFF',
                }}
                type="onSumbit"
              >
                Login
              </Button>
            </Segment>
          </Form>
          <Message>
            <p>
              If you log in the account, you must agree with the
              <a href="https://www.cmind-ai.com/terms" target="_blank">
                &nbsp;TERMS OF USE&nbsp;
              </a>
              .
            </p>
            <p>
              <small>
                New to us? Please visit our
                <a href="https://www.cmind-ai.com/contact">
                  &nbsp;CONTACT US&nbsp;
                </a>{' '}
                page to apply for an account.
              </small>
            </p>
          </Message>
        </div>
      </Grid.Column>
    </Grid>
  )
}

export default LogIn
