import React from 'react'
import { Icon } from 'semantic-ui-react'
import { useGridContext } from './GridProvider'
import styled from 'styled-components'
import { themer } from '../themer'

const ExportIcon = styled(Icon)`
  margin-left: ${themer.space} !important;
  cursor: pointer;
`
export const GirdExport = () => {
  const { api } = useGridContext()

  const onClickToDownload = () => {
    api.current.exportDataAsCsv()
  }
  return (
    <ExportIcon
      name="download"
      onClick={onClickToDownload}
      size="large"
      className="forst-color"
    />
  )
}
