import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Beat = styled.span`
  color: green;
`

const Miss = styled.span`
  color: red;
`

export const BeatMiss = ({ value }) => {
  if (typeof value === 'number') {
    if (value === 1) {
      return <Beat>Beat</Beat>
    } else if (value < 1) {
      return <Miss>Miss</Miss>
    }
    return value.toFixed(2)
  }
  return value ?? null
}

BeatMiss.propTypes = {
  value: PropTypes.element,
}
