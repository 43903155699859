export interface NumberFormatterProps {
  value?: string | number | null
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  emptyValue?: string | null
}

export const NumberFormatter = ({
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  emptyValue = 'N/A',
}: NumberFormatterProps) => {
  if (value === null || value === undefined) return emptyValue
  const numericValue = Number(value)

  if (isNaN(numericValue)) return emptyValue

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(numericValue)
}

export type NumberValue = undefined | string | number | null

export const useNumberFormatter = ({
  minimumFractionDigits,
  maximumFractionDigits,
  emptyValue,
}: NumberFormatterProps = {}) => {
  return (value?: NumberValue) =>
    NumberFormatter({
      value,
      minimumFractionDigits,
      maximumFractionDigits,
      emptyValue,
    })
}
