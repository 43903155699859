import React from 'react'
import { Input } from 'semantic-ui-react'
import { useGridContext } from './GridProvider'

export const GridQuickSearch = () => {
  const { api } = useGridContext()

  const onChange = (e) => api.current.setQuickFilter(e.target.value)

  return <Input icon="search" placeholder="Search..." onChange={onChange} />
}
