import { Form, Segment, Dropdown, Input, Button } from 'semantic-ui-react'
import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { EpsBeatsModal } from './Model'

const optionsYear = [
  { key: '2018', text: '2018', value: '2018' },
  { key: '2019', text: '2019', value: '2019' },
  { key: '2020', text: '2020', value: '2020' },
  { key: '2021', text: '2021', value: '2021' },
  { key: '2022', text: '2022', value: '2022' },
  { key: '2023', text: '2023', value: '2023' },
]

const optionsQuarter = [
  { key: '1', text: '1', value: 'Q1' },
  { key: '2', text: '2', value: 'Q2' },
  { key: '3', text: '3', value: 'Q3' },
  { key: '4', text: '4', value: 'Q4' },
]

const YearQuarterContiner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    max-width: 220px;
    width: 220px;
  }
`

export const QuickLookUp = () => {
  const [formState, setFormState] = useState({
    InputTicker: null,
    InputYear: null,
    InputQuarter: null,
    agreement: false,
  })
  const [isModalOpen, setModalOpen] = useState(false)
  const handleChange = (e, props) => {
    let value = props.value
    if (props.name === 'agreement') value = props.checked
    setFormState((prev) => ({ ...prev, [props.name]: value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { InputTicker, InputYear, InputQuarter, agreement } = formState
    if (
      !_.isEmpty(InputTicker) &&
      !_.isEmpty(InputYear) &&
      !_.isEmpty(InputQuarter) &&
      agreement
    ) {
      setModalOpen(true)
    } else {
      alert('Please fill in all the required fields!')
    }
  }

  return (
    <Segment stacked style={{ marginTop: 0 }}>
      <p
        style={{
          fontSize: '18px',
          marginBottom: '2rem',
          fontWeight: 'bold',
        }}
      >
        Visitors, you are welcome to see our prediction results of the companies
        of your interest.
      </p>
      <Form size="large" onSubmit={onSubmit}>
        <Form.Field
          inline
          control={Input}
          placeholder="Forecast Ticker"
          name="InputTicker"
          required
          value={formState.InputTicker}
          onChange={handleChange}
          fluid
        />
        <YearQuarterContiner>
          <Form.Field
            inline
            name="InputYear"
            control={Dropdown}
            options={optionsYear}
            required
            value={formState.InputYear}
            onChange={handleChange}
            selection
            placeholder="Forecast Year"
            fluid
          />
          <Form.Field
            inline
            name="InputQuarter"
            control={Dropdown}
            options={optionsQuarter}
            required
            value={formState.InputQuarter}
            onChange={handleChange}
            selection
            placeholder="Forecast Quarter"
            fluid
          />
        </YearQuarterContiner>
        <Form.Checkbox
          inline
          required
          label="I agree to the Terms and Conditions"
          name="agreement"
          checked={formState.agreement}
          onChange={handleChange}
        />
        <Button
          fluid
          size="large"
          style={{ backgroundColor: '#033be7', color: '#FFF' }}
          type="onSumbit"
        >
          Submit
        </Button>
        {isModalOpen && (
          <EpsBeatsModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            inputData={formState}
          />
        )}
      </Form>
    </Segment>
  )
}
