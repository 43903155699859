import React, { Suspense, lazy } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import { Header } from '../../components/Header'
import { permissionEnum, useGlobalState } from '../../components/shared'
import { Navigation } from '../../components/Navigation'

const ChangePassword = lazy(() => import('../ChangePassword'))
const Market = lazy(() => import('../Market/Market'))
const MostSearchedCompanies = lazy(
  () => import('../Company/MostSearchedCompanies')
)
const CompanyRoute = lazy(() => import('../Company/index'))
const CompanyEPSRoute = lazy(
  () => import('../Company/CompanyEPS/CompanyEPSRoute')
)
const CompanyRevenueRoute = lazy(
  () => import('../Company/CompanyRevenue/CompanyRevenueRoute')
)
const CompanyRiskScoreRoute = lazy(
  () => import('../Company/CompanyRiskScore/CompanyRiskScoreRoute')
)
const CompanyPeerGroupRoute = lazy(
  () => import('../Company/CompanyPeerGroup/CompanyPeerGroupRoute')
)
const CompanyDataSourceAndAnalyticsRoute = lazy(
  () =>
    import(
      '../Company/CompanyDataSourceAndAnalytics/CompanyDataSourceAndAnalyticsRoute'
    )
)
const CalendarRoute = lazy(() => import('../Calendar/CalendarRoute'))
const PortfolioRoute = lazy(() => import('../Portfolio/PortfolioRoute'))

const App = () => {
  const { permissions, defaultLandingPage } = useGlobalState()

  return (
    <>
      <Header />
      <Navigation />
      <Suspense fallback={<Loader size="huge" active content="Loading" />}>
        <Switch>
          {permissions.includes(permissionEnum.MARKET_EPS) && (
            <Route exact path="/market/eps">
              <Market type={permissionEnum.MARKET_EPS} />
            </Route>
          )}
          {permissions.includes(
            permissionEnum.MARKET_COMPANY_RANKING_QUARTERLY
          ) && (
            <Route
              exact
              path="/market/financial-reporting-risk/company-ranking-quarterly"
            >
              <Market type={permissionEnum.MARKET_COMPANY_RANKING_QUARTERLY} />
            </Route>
          )}
          {permissions.includes(
            permissionEnum.MARKET_COMPANY_RANKING_ANNUAL
          ) && (
            <Route
              exact
              path="/market/financial-reporting-risk/company-ranking-annual"
            >
              <Market type={permissionEnum.MARKET_COMPANY_RANKING_ANNUAL} />
            </Route>
          )}
          {permissions.includes(permissionEnum.COMPANY_SEARCH) && (
            <Route
              path="/company/most-searched-companies"
              component={MostSearchedCompanies}
            />
          )}
          {permissions.includes(permissionEnum.COMPANY_EPS) && (
            <Route path="/company/eps" component={CompanyEPSRoute} />
          )}
          {permissions.includes(permissionEnum.COMPANY_REVENUE) && (
            <Route path="/company/revenue" component={CompanyRevenueRoute} />
          )}
          {permissions.includes(permissionEnum.COMPANY_RISK_SCORE) && (
            <Route
              path="/company/risk-score"
              component={CompanyRiskScoreRoute}
            />
          )}
          {permissions.includes(permissionEnum.COMPANY_PEER_GROUP) && (
            <Route
              path="/company/peer-group"
              component={CompanyPeerGroupRoute}
            />
          )}
          {permissions.includes(
            permissionEnum.COMPANY_DATA_SOURCE_AND_ANALYTICS
          ) && (
            <Route
              path="/company/data-source-and-analytics"
              component={CompanyDataSourceAndAnalyticsRoute}
            />
          )}
          {permissions.includes(permissionEnum.COMPANY_SEARCH) && (
            <Route path="/company/:ticker" component={CompanyRoute} />
          )}
          {permissions.includes(permissionEnum.COMPANY_SEARCH) && (
            <Route path="/calendar" component={CalendarRoute} />
          )}
          {permissions.includes(permissionEnum.COMPANY_SEARCH) && (
            <Route path="/portfolio" component={PortfolioRoute} />
          )}
          <Route exact path="/change-password" component={ChangePassword} />
          <Route path="*">
            <Redirect to={{ pathname: defaultLandingPage }} />
          </Route>
        </Switch>
      </Suspense>
    </>
  )
}

export default App
