import React, { createContext, useContext, useRef } from 'react'
import PropTypes from 'prop-types'

const GridContext = createContext(null)

export const useGridContext = () => {
  const context = useContext(GridContext)
  if (context === null)
    throw new Error('useGridContext must be used within GridProvider.')
  return context
}

export const GridProvider = ({ children, id }) => {
  const api = useRef(null)
  const columnApi = useRef(null)

  return (
    <GridContext.Provider value={{ id, api, columnApi }}>
      {children}
    </GridContext.Provider>
  )
}

GridProvider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
}
