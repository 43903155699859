import React from 'react'
import { useEffect, useState } from 'react'
import { Button, Modal, Loader, Grid, Dimmer, Segment } from 'semantic-ui-react'
import _ from 'lodash'

const endPoint = 'https://cminder.cmind-ai.com/eps-quick-look-up'

const hiddenAttribute = [
  '_id',
  'CIK',
  'Current eps',
  'Current eps_est',
  'Forecast eps_est',
  'MarketCap',
  'Last Update Time',
]

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#f7f7f7',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
  },
  item: {
    flex: '0 0 calc(50% - 16px)',
    marginBottom: '16px',
  },
  attribute: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  value: {
    marginLeft: '8px',
  },
}

export const EpsBeatsModal = ({
  isModalOpen = false,
  setModalOpen,
  inputData = {},
}) => {
  const { InputTicker, InputYear, InputQuarter } = inputData
  const [epsBeats, setEpsBeats] = useState(null)

  useEffect(() => {
    fetch(
      `${endPoint}?` +
        new URLSearchParams({
          Ticker: _.toUpper(InputTicker),
          'Forecast Period_Year': InputYear,
          'Forecast Period': InputQuarter,
        })
    )
      .then((res) => res.json())
      .then((res) => {
        setEpsBeats(res.data)
      })
  }, [])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal onClose={() => setModalOpen(false)} open={isModalOpen}>
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column>{InputTicker}</Grid.Column>
          <Grid.Column textAlign="right">
            Forecast Quarter:{`${InputYear} - ${InputQuarter}`}
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {!epsBeats && (
              <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
                <Segment>
                  <Dimmer active inverted>
                    <Loader />
                  </Dimmer>
                </Segment>
              </div>
            )}
            {epsBeats && (
              <div className="container">
                <div style={styles.container}>
                  {epsBeats.length === 0 && (
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                      }}
                    >
                      Please double check your input tickers, forecast year and
                      quarter, <br />
                      or contact info@cmind-ai.com for more information.
                    </div>
                  )}
                  {epsBeats.map((epsBeat) => {
                    const keys = Object.keys(epsBeats[0])
                    return (
                      <div key={epsBeat.Ticker} style={styles.item}>
                        {keys.map(
                          (key) =>
                            !hiddenAttribute.includes(key) && (
                              <p key={key}>
                                <span style={styles.attribute}>{key}:</span>
                                <span style={styles.value}>
                                  {key === 'Prediction Probability'
                                    ? `${(epsBeat[key] * 100).toFixed(1)}%`
                                    : key === 'Prediction Label'
                                      ? epsBeat[key] === 1
                                        ? 'Beat'
                                        : 'Miss'
                                      : epsBeat[key]}
                                </span>
                              </p>
                            )
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setModalOpen(false)}>
          Back
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
