import { Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import { themer } from '../themer'

const StyledContainer = styled.div`
  margin-left: ${themer.textIndent};
  margin-bottom: ${_.getOr(false, 'removeMarginBottom')
    ? themer.space
    : undefined};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${themer.space};
`

const CompareItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${themer.space};
`

const CompareItem = styled.div`
  width: ${_.get('$width')};
  padding-right: ${themer.space};
`

const Label = styled.div`
  width: ${_.get('$width')};
  min-width: ${_.get('$width')};
  max-width: ${_.get('$width')};
`

export const AccordionContent = ({
  label,
  children,
  labelWidth,
  isCompareAble,
  compareItemWidth,
  firstCompareItem,
  secondCompareItem,
  removeMarginBottom,
  maximumDecimalNumber = 2,
  shouldFormatNumber = true,
}) => {
  const isFirstCompareItemNumber = typeof firstCompareItem === 'number'
  const isSecondCompareItemNumber = typeof secondCompareItem === 'number'
  const isChildrenNumber = typeof children === 'number'

  const firstItem = isFirstCompareItemNumber
    ? firstCompareItem.toFixed(maximumDecimalNumber)
    : firstCompareItem
  const secondItem = isSecondCompareItemNumber
    ? secondCompareItem.toFixed(maximumDecimalNumber)
    : secondCompareItem
  const childrenItem = isChildrenNumber
    ? children.toFixed(maximumDecimalNumber)
    : children

  return (
    <StyledContainer removeMarginBottom={removeMarginBottom}>
      <Label $width={labelWidth}>
        {label && <Header sub>{label}:</Header>}
      </Label>
      {isCompareAble && (
        <CompareItemContainer>
          <CompareItem $width={compareItemWidth}>
            {shouldFormatNumber ? firstItem : firstCompareItem}
          </CompareItem>
          <CompareItem $width={compareItemWidth}>
            {shouldFormatNumber ? secondItem : secondCompareItem}
          </CompareItem>
        </CompareItemContainer>
      )}
      {!isCompareAble && (shouldFormatNumber ? childrenItem : children)}
    </StyledContainer>
  )
}

AccordionContent.propTypes = {
  label: PropTypes.element,
  labelWidth: PropTypes.string,
  isCompareAble: PropTypes.bool,
  compareItemWidth: PropTypes.string,
  firstCompareItem: PropTypes.element,
  secondCompareItem: PropTypes.element,
  removeMarginBottom: PropTypes.bool,
  children: PropTypes.element,
  maximumDecimalNumber: PropTypes.number,
  shouldFormatNumber: PropTypes.bool,
}
