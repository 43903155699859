import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const ScoreContainer = styled.span`
  .color-red {
    color: red;
  }
  .color-green {
    color: green;
  }
`

const getArrow = (latestScore, previousScore) => {
  if (previousScore === null || latestScore > previousScore) {
    return <Icon name="arrow up" className="color-green" />
  } else if (latestScore === previousScore) {
    return null
  } else {
    return <Icon name="arrow down" className="color-red" />
  }
}

export const PortfolioScoreRenderer = ({ value, data, colDef }) => {
  if (!value) {
    return null
  }
  const { field } = colDef
  const scores = _.get(data, field, [])
  const latestScore = _.get(scores, ['0', 'score'], null)
  const previousScore = _.get(scores, ['1', 'score'], null)

  return (
    <ScoreContainer>
      {value}
      {getArrow(latestScore, previousScore)}
    </ScoreContainer>
  )
}

PortfolioScoreRenderer.propTypes = {
  value: PropTypes.number,
  data: PropTypes.any,
  colDef: PropTypes.object,
}
