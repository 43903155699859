import ReactDOM from 'react-dom'
import React from 'react'
import { HashRouter } from 'react-router-dom'

import App from './pages/App/App'
import { GlobalStateProvider } from './components/shared'
import { CompanyProvider } from './components/CompanyProvider'
import LogIn from './pages/LogIn'

import 'semantic-ui-less/semantic.less'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css' // Optional theme CSS
import './styles.css'
import 'react-datepicker/dist/react-datepicker.css'

//TODO: need to remove after complete quick loop up feature
if (window.location.pathname === '/quick-look-up')
  ReactDOM.render(<LogIn />, document.getElementById('root'))
else
  ReactDOM.render(
    <GlobalStateProvider>
      <CompanyProvider>
        <HashRouter>
          <App />
        </HashRouter>
      </CompanyProvider>
    </GlobalStateProvider>,
    document.getElementById('root')
  )
