import { isNil, get } from 'lodash'
import numeral from 'numeral'

export const DEFAULT_EMPTY_CELL = 'N/A'

export const numberFormatter = ({ value, colDef }) => {
  const format = get(colDef, 'cellRendererParams.format') || '0,0.00'

  if (isNil(value)) return DEFAULT_EMPTY_CELL
  if (typeof value === 'number') return numeral(value).format(format)
  return value
}

export const beatMissFormatter = ({ value }) => {
  if (isNil(value)) return DEFAULT_EMPTY_CELL

  const booleanValue = Boolean(value)
  if (booleanValue) return 'Beat'
  return 'Miss'
}

export const predictionCategoryFormatter = ({ value }) => {
  if (typeof value === 'number') {
    if (value > 0.8 && value <= 1) return 'Very Likely Beat'
    else if (value > 0.6 && value <= 0.8) return 'Likely Beat'
    else if (value > 0.4 && value <= 0.6) return 'Marginal'
    else if (value > 0.2 && value <= 0.4) return 'Likely Miss'
    else if (value > 0 && value <= 0.2) return 'Very Likely Miss'
  }
  return DEFAULT_EMPTY_CELL
}
