import React from 'react'
import { Container, GridProps, Menu, Segment } from 'semantic-ui-react'

interface PageProps extends GridProps {
  children: React.ReactNode
  tabs?: React.ReactNode[]
  top?: React.ReactNode
  loading?: boolean
}

export const Page = ({ children, tabs = [], top, loading }: PageProps) => {
  return (
    <Container>
      {top}
      {tabs?.length > 0 && <Menu attached="top">{tabs}</Menu>}
      <Segment
        loading={loading}
        attached={tabs?.length > 0 ? 'bottom' : undefined}
      >
        {children}
      </Segment>
    </Container>
  )
}
