import React, { MouseEvent, useRef } from 'react'
import {
  Chart as ReactChart,
  ChartProps,
  getElementAtEvent,
} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  InteractionItem,
  LineController,
  BarController,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Menu,
  MenuItem,
} from 'semantic-ui-react'

import { pluginEmptyOverlay } from './plugins'
import { CHART_COLOR, CHART_HEIGHT } from './ChartConfigs'
import { ChartContainer, ChartHelperContainer } from './ChartContainer'
import { jsonToCSV } from 'react-papaparse'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LineController,
  BarController,
  pluginEmptyOverlay,
  annotationPlugin,
  ChartDataLabels
)

ChartJS.defaults.maintainAspectRatio = false
ChartJS.defaults.scale.grid.drawOnChartArea = false
ChartJS.defaults.backgroundColor = CHART_COLOR.blue
ChartJS.defaults.datasets.line.tension = 0.3
ChartJS.defaults.plugins.datalabels = { display: false }

const Chart = ({
  type = 'line',
  height = CHART_HEIGHT,
  onChartElementClick,
  onClick,
  ...rest
}: ChartProps & AdditionalChartProps) => {
  const chartRef = useRef<ChartJS>(null)

  const onChartClick = (event: MouseEvent<HTMLCanvasElement>) => {
    onClick?.(event)
    const chart = chartRef?.current
    if (!chart) {
      return
    }
    const element = getElementAtEvent(chart, event)
    if (!element.length) return
    onChartElementClick?.(element[0])
  }

  const exportImage = () => {
    const chart = chartRef?.current
    if (!chart) return
    const link = document.createElement('a')
    link.href = chart.toBase64Image()
    link.download = 'download.png'
    link.click()
  }

  const exportCSV = () => {
    const chart = chartRef?.current
    if (!chart) return
    const dataSets = chart.getSortedVisibleDatasetMetas()
    const csvFields = ['']
    const xAxisTicks = chart.scales.x.ticks.map((tick) => tick.label)
    const csvData = xAxisTicks.map((tick) => [tick])
    dataSets.forEach((data) => {
      const parsed = data._parsed
      const label = data.label
      csvFields.push(label)
      parsed.forEach((p: any) => {
        csvData[p.x].push(p.y)
      })
    })
    const csv =
      'data:text/csv;charset=utf-8,' +
      jsonToCSV({
        fields: csvFields,
        data: csvData,
      })
    const link = document.createElement('a')
    link.href = encodeURI(csv)
    link.download = 'download.csv'
    link.click()
  }
  return (
    <>
      <ChartHelperContainer>
        <Menu floated="right" text>
          <MenuItem>
            <Dropdown icon="download" item>
              <DropdownMenu>
                <DropdownItem onClick={exportImage}>Export Image</DropdownItem>
                <DropdownItem onClick={exportCSV}>Export CSV</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </MenuItem>
        </Menu>
      </ChartHelperContainer>
      <ChartContainer height={height}>
        <ReactChart
          type={type}
          {...rest}
          ref={chartRef}
          onClick={onChartClick}
        />
      </ChartContainer>
    </>
  )
}

export interface AdditionalChartProps {
  height?: number
  onChartElementClick?: (event: InteractionItem) => void
}

export { Chart, ChartJS }
