import {
  Menu,
  Image,
  Icon,
  Dropdown,
  MenuMenu,
  Sticky,
  ImageProps,
} from 'semantic-ui-react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'

import { useGlobalState, permissionEnum } from '../shared'
import { logout } from '../../logout'
import styled from 'styled-components'

const Logo = styled(Image)<ImageProps>`
  filter: invert(1);
  height: 25px;
  width: auto;
`

export const Header = () => {
  const { username, permissions } = useGlobalState()
  const history = useHistory()
  const { pathname } = useLocation()

  const changePassHandler = () => history.push('/change-password')

  const isMenuActive = (url: string) => url === pathname

  return (
    <Sticky context={document.body}>
      <Menu attached="top" borderless size="massive" inverted color="blue">
        <Menu.Item>
          <Link to="/">
            <Logo src="/assets/main_logo.png" />
          </Link>
        </Menu.Item>
        {permissions.includes(permissionEnum.ASSET_MANAGEMENT_TRIAL) && (
          <Menu.Item active={isMenuActive('/trial')}>
            <NavLink to="/trial">Asset Management (Trial)</NavLink>
          </Menu.Item>
        )}
        {permissions.includes(permissionEnum.ASSET_MANAGEMENT) && (
          <Menu.Item active={isMenuActive('/asset-management')}>
            <NavLink to="/asset-management">Asset Management</NavLink>
          </Menu.Item>
        )}
        {permissions.includes(permissionEnum.LITIGATION) && (
          <Menu.Item active={isMenuActive('/litigation')}>
            <NavLink to="/litigation">Litigation</NavLink>
          </Menu.Item>
        )}
        {permissions.includes(permissionEnum.ADMIN) && (
          <Menu.Item>
            <a href="/Cmind_User_Manual.pdf" target="_blank">
              User Manual
            </a>
          </Menu.Item>
        )}
        <MenuMenu position="right">
          <Dropdown
            trigger={
              <span>
                <Icon name="user" /> {username}
              </span>
            }
            item
            floating
          >
            <Dropdown.Menu>
              {permissions.includes(permissionEnum.ADMIN) && (
                <Dropdown.Item
                  onClick={() =>
                    window.open(
                      `http://157.245.112.219:8081?user=${window.localStorage.getItem(
                        'cmind-username'
                      )}&token=${window.localStorage.getItem('token')}`,
                      '_blank'
                    )
                  }
                >
                  Administration
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={changePassHandler}>
                Change Password
              </Dropdown.Item>
              <Dropdown.Item onClick={() => logout()}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </MenuMenu>
      </Menu>
    </Sticky>
  )
}
