const storedItem = ['cmind-username', 'token', 'clientType']

export const logout = async (shouldLogin = false) => {
  const username = localStorage.getItem('cmind-username')
  await fetch(`https://cminder.cmind-ai.com/user-logs`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      username,
      action: 'LOGOUT',
    }),
  })
  storedItem.forEach((item) => localStorage.removeItem(item))
  if (shouldLogin) window.location.href = '/login.html'
  else window.location.href = '/logout.html'
}
