import { createContext, useContext } from 'react'

export interface CompanyStateType {
  tic?: string
  text?: string
}

export interface CompanyContextType extends CompanyStateType {
  updateCompany: (newCompanyState: CompanyStateType) => void
}

export const CompanyContext = createContext<CompanyContextType | null>(null)

export const useCompany = () => {
  const context = useContext(CompanyContext)
  if (!context) throw new Error('use Company must use in CompanyProvider')
  return context
}
