import { Chart } from 'chart.js'

export default {
  id: 'empty-overlay',
  afterDraw(chart: Chart) {
    const isEmpty = chart.data.datasets.reduce((prev, dataset) => {
      return prev && dataset.data.length < 1
    }, true)
    if (isEmpty) {
      let ctx = chart.ctx
      let width = chart.width
      let height = chart.height
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText('No data', width / 2, height / 2)
      ctx.restore()
    }
  },
}
