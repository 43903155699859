// chart height
export const BAR_CHART_HEIGHT = 400
export const LINE_CHART_HEIGHT = 300
export const CHART_HEIGHT = 400

// chart colors
export const CHART_COLOR = {
  green: '#00ff00',
  lightBlue: '#00ffff',
  blue: '#033be7',
  purple: '#d443f5',
}

// chart configs
export const CHART_CONFIGS = {}
