export enum permissionEnum {
  ASSET_MANAGEMENT = 'ASSET_MANAGEMENT',
  ASSET_MANAGEMENT_TRIAL = 'ASSET_MANAGEMENT_TRIAL',
  LITIGATION = 'LITIGATION',
  ADMIN = 'ADMIN',
  MARKET = 'MARKET',
  MARKET_EPS = 'MARKET_EPS',
  MARKET_FINANCIAL_REPORTING_RISK = 'MARKET_FINANCIAL_REPORTING_RISK',
  MARKET_COMPANY_RANKING_QUARTERLY = 'MARKET_COMPANY_RANKING_QUARTERLY',
  MARKET_COMPANY_RANKING_ANNUAL = 'MARKET_COMPANY_RANKING_ANNUAL',
  COMPANY_SEARCH = 'COMPANY_SEARCH',
  COMPANY_EPS = 'COMPANY_EPS',
  COMPANY_REVENUE = 'COMPANY_REVENUE',
  COMPANY_RISK_SCORE = 'COMPANY_RISK_SCORE',
  COMPANY_PEER_GROUP = 'COMPANY_PEER_GROUP',
  COMPANY_QUARTERLY_PEER_GROUP = 'COMPANY_QUARTERLY_PEER_GROUP',
  COMPANY_DATA_SOURCE_AND_ANALYTICS = 'COMPANY_DATA_SOURCE_AND_ANALYTICS',
  CALENDAR = 'CALENDAR',
  PORTFOLIO = 'PORTFOLIO',
}
