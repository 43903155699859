import React, { useContext, createContext, useState } from 'react'
import { themer } from '../themer'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const SubAccordionContext = createContext(null)

export const useSubAccordion = () => {
  const context = useContext(SubAccordionContext)
  if (context === null)
    throw new Error('useSubAccordion must be used within SubAccordionProvider.')
  return context
}

const StyledButton = styled.div`
  cursor: pointer;
  border: none;
  font-size: 12px;
`

export const SubAccordionContainer = styled.div`
  margin-left: ${themer.textIndent};
`

export const SubAccordionProvider = ({
  defaultIsExpandAll = false,
  children,
}) => {
  const [isExpandAll, setExpandAll] = useState(defaultIsExpandAll)

  const toggleAll = () => {
    setExpandAll(!isExpandAll)
  }

  return (
    <SubAccordionContext.Provider value={{ isExpandAll, setExpandAll }}>
      <StyledButton onClick={toggleAll}>
        <Icon name={isExpandAll ? 'expand' : 'expand arrows alternate'} />
        <span>{isExpandAll ? 'Collapse All' : 'Expand All'}</span>
      </StyledButton>
      {children}
    </SubAccordionContext.Provider>
  )
}

SubAccordionProvider.propTypes = {
  defaultIsExpandAll: PropTypes.bool,
  children: PropTypes.element,
}
