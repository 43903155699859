import { NumberValue } from '../NumberFormatter'

export interface CurrencyFormatterProps {
  value?: NumberValue
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  notation?: 'compact' | 'standard' | 'scientific' | 'engineering'
  currency?: string
  emptyValue?: string | null
  style?: 'decimal' | 'percent' | 'currency'
}

export const CurrencyFormatter = ({
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  notation = 'compact',
  currency = 'USD',
  emptyValue = 'N/A',
  style = 'currency',
}: CurrencyFormatterProps) => {
  if (value === null || value === undefined) return emptyValue
  const numericValue = Number(value)

  if (isNaN(numericValue)) return emptyValue

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
    notation,
    currency,
    style,
  }).format(numericValue)
}

export const useCurrencyFormatter = ({
  minimumFractionDigits,
  maximumFractionDigits,
  notation,
  currency,
  emptyValue,
  style,
}: CurrencyFormatterProps = {}) => {
  return (value?: NumberValue) =>
    CurrencyFormatter({
      value,
      minimumFractionDigits,
      maximumFractionDigits,
      notation,
      currency,
      emptyValue,
      style,
    })
}
