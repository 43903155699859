import React, { useCallback, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const initialState = {
  loading: false,
  results: [],
  value: '',
}

function searchReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection }

    default:
      throw new Error()
  }
}

export function SearchTicker({
  commonStore,
  onSelected,
  placeholder,
  ...rest
}) {
  const timeoutRef = useRef()
  const [state, dispatch] = useReducer(searchReducer, initialState)
  const { loading, results, value } = state

  const getOptions = useCallback((searchQuery) => {
    const q = searchQuery
    if (q) {
      let arr = []
      if (q.match('^[0-9]*$')) {
        arr.push(
          new Promise((resolve) => {
            return resolve(commonStore.usCompaniesGetCIK(q))
          })
        )
      } else {
        arr.push(
          new Promise((resolve) => {
            return resolve(commonStore.usCompaniesGetTickerLike(q))
          })
        )
        arr.push(
          new Promise((resolve) => {
            return resolve(commonStore.usCompaniesGetTextLike(q))
          })
        )
      }

      return Promise.all(arr)
        .then((results) => {
          // sort result here, Navie sort
          if (results[0] && results[0].length >= 1)
            results[0].sort((a, b) => {
              return a.value.length - b.value.length
            })

          if (results[1] && results[1].length >= 1)
            results[1].sort((a, b) => {
              return a.text.length - b.text.length
            })

          if (results[2] && results[2].length >= 1)
            results[2].sort((a, b) => {
              return a.cik.length - b.cik.length
            })

          // remove duplication here
          results = results.flat()
          let clean_results = []
          results.forEach((rs) => {
            if (clean_results.findIndex((x) => x.value === rs.value) === -1)
              clean_results.push(rs)
          })
          return clean_results.slice(0, 20)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
    }
  })

  const _handleSearchChange = React.useCallback((e, { searchQuery }) => {
    clearTimeout(timeoutRef.current)
    dispatch({ type: 'START_SEARCH', query: searchQuery })
    if (typeof onSelected === 'function') onSelected({ value: null })
    timeoutRef.current = setTimeout(async () => {
      if (searchQuery.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' })
        return
      }

      const rawOptions = await getOptions(searchQuery)

      if (rawOptions) {
        const searchOptions = rawOptions.map((item) => ({
          text: item.value + ' / ' + item.text,
          value: item.value,
          key: item.value,
          cikvalue: item.cik,
        }))
        dispatch({
          type: 'FINISH_SEARCH',
          results: searchOptions,
        })
      }
    }, 300)
  }, [])

  const _onSelected = (e, data) => {
    const selectedOption = data.options.find(
      (item) => item.value === data.value
    )
    dispatch({ type: 'UPDATE_SELECTION', selection: selectedOption.value })
    if (typeof onSelected === 'function') onSelected(selectedOption)
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <Dropdown
      selection
      search
      loading={loading}
      placeholder={placeholder ?? 'Select company by ticker, name'}
      onChange={_onSelected}
      onSearchChange={_handleSearchChange}
      options={results}
      defaultValue={value}
      {...rest}
    />
  )
}

SearchTicker.propTypes = {
  commonStore: PropTypes.any,
  onSelected: PropTypes.func,
}
