import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'

export const PortfolioScoreTooltip = (props) => {
  const {
    value,
    colDef: { valueFormatter },
    valueLabel = 'value',
  } = props
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>YearQuarter</Table.HeaderCell>
          <Table.HeaderCell>{valueLabel}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {value.map((v, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>{v.yearQuarter}</Table.Cell>
              <Table.Cell>
                {typeof valueFormatter === 'function'
                  ? valueFormatter({
                      ...props,
                      value: v.score,
                    })
                  : v.score}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

PortfolioScoreTooltip.propTypes = {
  value: PropTypes.array,
  colDef: {
    valueFormatter: PropTypes.func,
  },
  valueLabel: PropTypes.string,
}
