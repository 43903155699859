import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownProps,
} from 'semantic-ui-react'
import { permissionEnum } from '../enum'
import { useGlobalState } from '../GlobalState'

type DropdownLinkItems = (DropdownItemProps & {
  to: string
  permission?: permissionEnum
})[]

type DropdownLinksProps = DropdownProps & {
  dropdownItems?: DropdownLinkItems
  permission?: permissionEnum
}

export const DropdownLinks = ({
  permission,
  dropdownItems,
  ...rest
}: DropdownLinksProps) => {
  const { permissions } = useGlobalState()
  const history = useHistory()
  const { pathname } = useLocation()

  if (permission && !permissions.includes(permission)) return null

  return (
    <Dropdown {...rest}>
      <DropdownMenu>
        {dropdownItems?.map(({ permission, to, ...rest }, idx) => {
          return permission && !permissions.includes(permission) ? null : (
            <DropdownItem
              key={idx}
              active={to === pathname}
              onClick={() => {
                history.push(to)
              }}
              {...rest}
            />
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}
