import React, { useState } from 'react'
import { CompanyContext, CompanyStateType } from './CompanyContext'

interface CompanyProviderProps {
  children: React.ReactNode
}

const defaultCompanyState = {}

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const [companyState, updateCompany] =
    useState<CompanyStateType>(defaultCompanyState)

  return (
    <CompanyContext.Provider value={{ ...companyState, updateCompany }}>
      {children}
    </CompanyContext.Provider>
  )
}
