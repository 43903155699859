import React from 'react'
import { useLocalStorage } from 'react-use'
import jwt_decode from 'jwt-decode'

import { GlobalStateContext, JwtBody } from './GlobalStateContext'
import { getPermissions } from './getPermissions'
import { logout } from '../../../logout'
import { clientTypeEnum } from '../enum'

export interface GlobalStateProviderProps {
  children: React.ReactNode
}

const defaultJwtBody: JwtBody = {
  aud: null,
  exp: 0,
  iat: 0,
  iss: null,
  jti: null,
  sub: null,
  userId: null,
}

const DEFAULT_LANDING_PAGE = '/market/eps'

export const GlobalStateProvider = ({ children }: GlobalStateProviderProps) => {
  const [username] = useLocalStorage<string>('cmind-username', undefined, {
    raw: true,
  })
  const [jwtToken] = useLocalStorage<string>('token', undefined, {
    raw: true,
  })
  const [clientType] = useLocalStorage<string>('clientType', undefined, {
    raw: true,
  })

  const permissions = getPermissions(clientType)

  if (!username || !jwtToken || !clientType) {
    logout(true)
    return null
  }
  let jwtBody = defaultJwtBody

  try {
    const decodedJwt = jwt_decode<JwtBody>(jwtToken)
    const isExpired = Date.now() >= decodedJwt.exp * 1000
    if (isExpired || !decodedJwt.userId) {
      logout(true)
      return null
    }
    jwtBody = decodedJwt
  } catch (e) {
    logout(true)
    console.error(e)
  }

  return (
    <GlobalStateContext.Provider
      value={{
        username,
        permissions,
        jwtBody,
        jwtToken,
        defaultLandingPage: DEFAULT_LANDING_PAGE,
        clientType: clientType as clientTypeEnum,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
