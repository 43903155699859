import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const TickerRenderer = ({ value, show }) => {
  if (!value || !show) return null
  return (
    <Link to={`/company/${value}`} rel="noopener noreferrer" target="_blank">
      {value}
    </Link>
  )
}

TickerRenderer.propTypes = {
  value: PropTypes.string,
  show: PropTypes.bool,
}
