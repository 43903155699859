import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import styled from 'styled-components'
import { useGridContext } from './GridProvider'
import * as components from './components'
import { isNil } from 'lodash'
import { DEFAULT_EMPTY_CELL, numberFormatter, beatMissFormatter } from './utils'

const GridContainer = styled.div`
  height: 600px;
  width: 100%;
`

const defaultColumnFormatter = ({ value }) => {
  if (isNil(value)) return DEFAULT_EMPTY_CELL
  return value
}

const defaultColumnDefs = {
  sortable: true,
  resizable: true,
  useValueFormatterForExport: true,
  valueFormatter: defaultColumnFormatter,
}

export const AgGrid = forwardRef(({ onGridReady, ...props }, ref) => {
  const gridContext = useGridContext()
  const { defaultColDef = {}, context = {}, ...rest } = props

  const onGridReadyFunc = (event) => {
    gridContext.api.current = event.api
    gridContext.columnApi.current = event.columnApi
    if (typeof onGridReady === 'function') onGridReady(event)
  }

  return (
    <GridContainer className="ag-theme-alpine">
      <AgGridReact
        ref={ref}
        onGridReady={onGridReadyFunc}
        defaultColDef={{ ...defaultColumnDefs, ...defaultColDef }}
        {...rest}
        components={components}
        context={{
          ...context,
          DEFAULT_EMPTY_CELL,
          numberFormatter,
          beatMissFormatter,
        }}
      >
        Grid
      </AgGridReact>
    </GridContainer>
  )
})

AgGrid.propTypes = {
  onGridReady: PropTypes.func,
}
