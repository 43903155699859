import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useGlobalState } from './GlobalState'

interface IFrameProps {
  url: string
  height?: string
  redirectOpen?: 'risk-score' | 'eps'
}

const StyledIFrame = styled.iframe<{ height: string }>`
  width: 100%;
  height: ${(props) => props.height};
  border: none;
  overflow-y: scroll;
`

export const IFrameChart = ({
  url,
  height,
  redirectOpen = 'eps',
}: IFrameProps) => {
  const [host] = window.location.href.split('#')
  const { clientType } = useGlobalState()
  useEffect(() => {
    const handleMessageFromIframe = (event: any) => {
      if (event.data.type === 'ticker') {
        window.open(
          `${host}#/company/${event.data.data}?open=${redirectOpen}`,
          '_blank'
        )
      }
    }

    window.addEventListener('message', handleMessageFromIframe, true)

    return () =>
      window.removeEventListener('message', handleMessageFromIframe, true)
  }, [clientType, host])

  if (!url || url === '') return null

  return <StyledIFrame src={url} height={height || '70vh'} />
}
