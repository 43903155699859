import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { MenuItem, MenuItemProps } from 'semantic-ui-react'
import { permissionEnum } from '../enum'
import { useGlobalState } from '../GlobalState'

export interface MenuLinkItemProps extends MenuItemProps {
  to: string
  permission?: permissionEnum
}

export const MenuLinkItem = ({
  to,
  children,
  permission,
  ...rest
}: MenuLinkItemProps) => {
  const { permissions } = useGlobalState()
  const history = useHistory()
  const { pathname } = useLocation()

  if (permission && !permissions.includes(permission)) return null

  return (
    <MenuItem
      active={to === pathname}
      onClick={() => {
        history.push(to)
      }}
      {...rest}
    >
      {children}
    </MenuItem>
  )
}
