import { createContext, useContext } from 'react'
import { clientTypeEnum, permissionEnum } from '../enum'

export interface JwtBody {
  aud: string | null
  exp: number
  iat: number
  iss: string | null
  jti: string | null
  sub: string | null
  userId: string | null
}

interface GlobalState {
  username: string
  permissions: permissionEnum[]
  jwtToken: string
  jwtBody: JwtBody
  defaultLandingPage: string
  clientType: clientTypeEnum
}

export const GlobalStateContext = createContext<GlobalState | null>(null)

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext)
  if (!context)
    throw new Error('useGlobalState must be used within GlobalStateProvider')
  return context
}
